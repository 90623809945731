
/**
 * Objet javascript qui gére l'adim ship
 * @type {{}|{}}
 */

export let  scShipPageManager = scShipPageManager || {} ;

scShipPageManager.htmlCloned = '';
scShipPageManager.domUploadOriginal = document.getElementById('sc-shipImageUpload-originalContainer');
scShipPageManager.domUploadClone = document.getElementById('sc-shipImageUpload-cloneContainer');
scShipPageManager.domUploadGroup = document.getElementById('sc-shipImageUpload-cloneContent');
scShipPageManager.addButton = document.getElementById('sc-shipImageUpload-addButton');
scShipPageManager.domModalDescriptionFr = document.getElementById('modal_description_fr');
scShipPageManager.domModalDescriptionEs = document.getElementById('modal_description_es');
scShipPageManager.domDescription = document.getElementById('description');
scShipPageManager.domDescriptionFr = document.getElementById('description_fr');
scShipPageManager.domDescriptionEs = document.getElementById('description_es');
scShipPageManager.domContent = document.getElementById('content');
scShipPageManager.domStartDate = document.getElementById('start_date');
scShipPageManager.domEndDate = document.getElementById('end_date');
scShipPageManager.domCruiseStartDate = document.getElementById('cruise_start_date');
scShipPageManager.domCruiseEndDate = document.getElementById('cruise_end_date');
scShipPageManager.domApp = document.getElementById('app');
scShipPageManager.darkModeButton = document.getElementById('sc-darkMode-button');
scShipPageManager.domLivewire = document.getElementById('sc-shipDatatables');
scShipPageManager.domShipDatatable = document.querySelector('.sc-shipDatatables');


if(scShipPageManager.domShipDatatable !== null) {
    scShipPageManager.domShipDatatable.firstElementChild.style.cssText = 'width:100%';
}

/**
 * Sauvegarde la recherche input dans le local storage
 */
if (scShipPageManager.domLivewire !== null) {
    scShipPageManager.domInputLivewire = scShipPageManager.domLivewire.querySelector('.pointer-events-none').nextElementSibling;
    //le dom input
    scShipPageManager.domInputLivewire.addEventListener('keyup', function(e) {
        var inputValueLivewire = scShipPageManager.domInputLivewire.value;
        localStorage.setItem('input_cabin', inputValueLivewire);
    });
};

/**
 * Permet d'ajouter ou supprimer un dom file
 */
if (scShipPageManager.addButton !== null) {
    scShipPageManager.addButton.onclick = function () {
        'use strict';
        scShipPageManager.htmlCloned = scShipPageManager.domUploadClone.cloneNode(true);
        scShipPageManager.htmlCloned.classList.remove('hidden');
        scShipPageManager.domUploadOriginal.after(scShipPageManager.htmlCloned);
    };
}

if (scShipPageManager.shipImageUploadRemove !== null) {
    scShipPageManager.shipImageUploadRemove = function (event) {
        'use strict';
        event.target.parentNode.parentNode.remove();
    };
}

/**
 * Transforme tous les textarea en CKEDITOR
 */

if (scShipPageManager.domDescription  !== null) {
    CKEDITOR.replace(scShipPageManager.domDescription);
}

if (scShipPageManager.domDescriptionFr  !== null) {
    CKEDITOR.replace(scShipPageManager.domDescriptionFr);
}

if (scShipPageManager.domDescriptionEs  !== null) {
    CKEDITOR.replace(scShipPageManager.domDescriptionEs);
}

if (scShipPageManager.domContent !== null) {
    CKEDITOR.replace(scShipPageManager.domContent);
}

/**
 * confirmation de suppression d'une image
 * @param target
 */
scShipPageManager.confirmDelete = function(target) {
    let action = target.href;
    let message = target.getAttribute('data-message');
    let formId = target.getAttribute('data-form');

    if (confirm(message)) {
        let form = document.getElementById(formId);
        form.action= action;
        form.submit();
    }
};

/**
 * Methode s'appliquant à l'ensemble des boutons de suppression
 */
document.addEventListener('click', function(e) {
    let target = e.target;
    if (target.className && target.classList.contains('delete-link')) {
        e.preventDefault();
        scShipPageManager.confirmDelete(e.target);
    }
});

/**
 * Gestion du dark mode au click sur le bouton
 */
if (scShipPageManager.darkModeButton !== null) {
    scShipPageManager.darkModeButton.onclick = function () {
        'use strict';
        let darkModeStorage = localStorage.getItem('darkmode');
        if (typeof darkModeStorage === 'undefined') {
            localStorage.setItem('darkmode', '1');
            scShipPageManager.domApp.classList.remove('sc-darkMode');
            scShipPageManager.domApp.className += ' sc-darkMode';
        } else {
            if (darkModeStorage === '1') {
                scShipPageManager.domApp.classList.remove('sc-darkMode');
                localStorage.setItem('darkmode', '0');
            } else {
                scShipPageManager.domApp.classList.remove('sc-darkMode');
                scShipPageManager.domApp.className += ' sc-darkMode';
                localStorage.setItem('darkmode', '1');
            }
        }
    };
};

/**
 * gestion du dark mode au chargement de la page
 * @type {string}
 */
scShipPageManager.loadDarkMode = function() {
    let darkModeStorage = localStorage.getItem('darkmode');
    if (darkModeStorage=== '1') {
        scShipPageManager.domApp.classList.remove('sc-darkMode');
        scShipPageManager.domApp.className += ' sc-darkMode' ;
    } else {
        scShipPageManager.domApp.classList.remove('sc-darkMode');
    }
};

/**
 * met à jour le contenu du CKEDITOR textarea des descriptifs fe et es
 * @param data
 * @param lang
 */
scShipPageManager.loadCkeditor = function(data, lang) {
    let instance = 'modal_description_fr';
    let dom = scShipPageManager.domModalDescriptionFr;
    if(lang === 'es') {
        instance = 'modal_description_es';
        dom = scShipPageManager.domModalDescriptionEs;
    }
    if (typeof CKEDITOR.instances[instance] !== 'undefined') {
        CKEDITOR.instances[instance].updateElement();
        CKEDITOR.instances[instance].setData(data);
    } else {
        CKEDITOR.replace(dom);
        CKEDITOR.instances[instance].setData(data);
    }
};





