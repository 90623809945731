
/**
 * Objet javascript qui gére l'adim port
 * @type {{}|{}}
 */

export let  scPortPageManager = scPortPageManager || {} ;

scPortPageManager.domBtnCollapseDescriptif = document.getElementById('buttonCollapseDescriptif');
scPortPageManager.domBtnCollapseSlug = document.getElementById('buttonCollapseSlug');
scPortPageManager.domBtnCollapseCompany = document.getElementById('buttonCollapseCompany');
scPortPageManager.domContentCollapseDescriptif = document.getElementById('contentCollapseDescriptif');
scPortPageManager.domContentCollapseSlug = document.getElementById('contentCollapseSlug');
scPortPageManager.domContentCollapseCompany = document.getElementById('contentCollapseCompany');


if (scPortPageManager.domBtnCollapseDescriptif !== null) {
    scPortPageManager.domBtnCollapseDescriptif.onclick = function () {
        'use strict';
        console.log('icicici');
        scPortPageManager.domContentCollapseSlug.classList.remove('show');
        scPortPageManager.domContentCollapseCompany.classList.remove('show');
    };
}
if (scPortPageManager.domBtnCollapseSlug !== null) {
    scPortPageManager.domBtnCollapseSlug.onclick = function () {
        'use strict';
        scPortPageManager.domContentCollapseDescriptif.classList.remove('show');
        scPortPageManager.domContentCollapseCompany.classList.remove('show');
    };
}
if (scPortPageManager.domBtnCollapseCompany !== null) {
    scPortPageManager.domBtnCollapseCompany.onclick = function () {
        'use strict';
        scPortPageManager.domContentCollapseDescriptif.classList.remove('show');
        scPortPageManager.domContentCollapseSlug.classList.remove('show');
    };
}
